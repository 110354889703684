import {
	articleTeaserImageSettings,
	ImageSetting,
} from "../portalstack.config";
import { TeaserVariant } from "../components/teasers/utils";
import {
	ImgixParams as CMSImgixParams,
	ImgixParamsCrop,
	ImgixParamsFit,
} from "../__generated__/types/graphql-codegen";

type ArticleImageVariant = "articleHero";

type TeaserImgixParams = Omit<
	Record<TeaserVariant, CMSImgixParams>,
	"list" | "text-only" | "single-eyecatcher" | "overview"
>;

type ArticleImgixParams = Record<ArticleImageVariant, CMSImgixParams>;

const imageQualityMin = 20;
const imageQualityMax = 60;
const imageQualityMaxWidth = 1280; // ideally the biggest image width on the whole site

export const getImgixParamsByWidthAndAspectRatio = ({
	width,
	fileAspectRatio,
}: ImageSetting): CMSImgixParams => {
	// inverse quality scaling: make small images better quality
	// and large images worse...
	//
	// a large image (imageQualityMaxWidth wide) will be only imageQualityMin % quality
	// small images can go up to just under 60% quality
	const q = Math.round(
		imageQualityMin +
			(1 - Math.min(width / imageQualityMaxWidth, 1)) *
				(imageQualityMax - imageQualityMin),
	);

	return {
		w: width,
		h: Math.floor(width / fileAspectRatio),
		fit: ImgixParamsFit.crop,
		crop: [ImgixParamsCrop.focalpoint],
		q,
	};
};

export const teaserImgixParams: TeaserImgixParams = {
	regular: getImgixParamsByWidthAndAspectRatio(
		articleTeaserImageSettings.regular,
	),
	hero: getImgixParamsByWidthAndAspectRatio(articleTeaserImageSettings.hero),
	outlined: getImgixParamsByWidthAndAspectRatio(
		articleTeaserImageSettings.outlined,
	),
};

export const articleImgixParams: ArticleImgixParams = {
	articleHero: getImgixParamsByWidthAndAspectRatio({
		fileAspectRatio: 2 / 1,
		width: 995,
	}),
};

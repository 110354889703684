import { chakra } from "@chakra-ui/react";
import { NextPage } from "next";
import React, { useEffect, useState, useMemo } from "react";
import { BrandSlug } from "../portalstack.config";
import { BrandWrapper } from "../components/BrandWrapper";
import { defaultBrand, getBrandFromUrl, getLocaleByBrand } from "../lib/config";
import { teaserImgixParams, articleImgixParams } from "../lib/imgix";
import { NotFoundPage } from "../templates/NotFoundPage";
import { isSSR } from "../utils/misc";
import { useGetBrandSettingsQuery } from "../__generated__/types/graphql-codegen-client";
import { getTitle } from "../utils/getStaticPropsUtils";
import { useEnrichedMetaTags } from "../hooks/useEnrichedMetaTags";

// prevent warning in console when running dev mode (https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85)
if (isSSR) React.useLayoutEffect = useEffect;

const Error404Page: NextPage = () => {
	const [brandSlug, setBrandSlug] = useState<BrandSlug>(defaultBrand.slug);

	const { data: fetchedData, isFetching: isLoading } =
		useGetBrandSettingsQuery({
			locale: getLocaleByBrand(brandSlug),
			brand: brandSlug,
			...articleImgixParams,
			...teaserImgixParams,
		});

	// next wants you to have a static 404 page,
	// so we render nothing first and fix it after loading the brandSettings
	React.useLayoutEffect(() => {
		const getBrand = async () => {
			const url = window.location.href;
			const brand = getBrandFromUrl(url);

			setBrandSlug(brand?.slug ?? brandSlug);
		};

		getBrand();
	}, [brandSlug]);

	const brandSettings = fetchedData?.settings;

	const metaTags = useEnrichedMetaTags(brandSettings?.seo ?? [], brandSlug);

	const title = useMemo(
		() => (brandSettings ? getTitle(brandSettings) : "404"),
		[brandSettings],
	);

	return isLoading || !brandSettings ? null : (
		<chakra.div
			sx={{
				"--bg-color": "base.lightGray",
			}}
		>
			<BrandWrapper
				brandSettings={brandSettings}
				brandSlug={brandSlug}
				metaTags={metaTags}
				isPreview={false}
				title={title}
				canonicalHref={false}
			>
				<NotFoundPage />
			</BrandWrapper>
		</chakra.div>
	);
};

export default Error404Page;

import {
	chakra,
	Container,
	createStylesContext,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React from "react";
import { useBrandLogo } from "../brandComponents";
import { Hx } from "../components/headings";
import { Link } from "../components/Link/Link";
import { useRewriteContext } from "../context/RewriteContext";
import { useTranslatedString } from "../i18n/hooks";

type NotFoundPageProps = {
	// allOtherBrands: AllOtherBrands;
};
// TODO: uncomment when more brands are available
// const OtherBrand: ReactFC<{ brandSlug: BrandSlug; description?: string }> = ({
// 	brandSlug,
// 	description,
// 	...rest
// }) => {
// 	const Logo = useBrandLogo(brandSlug);
// 	const styles = useStyles();

// 	return (
// 		<chakra.li {...rest}>
// 			<BrandSafeLink
// 				brand={brandSlug}
// 				href="/"
// 				sx={styles.otherBrandsLink}
// 			>
// 				<Logo sx={styles.otherBrandsLogo} />
// 				<p>{description}</p>
// 			</BrandSafeLink>
// 		</chakra.li>
// 	);
// };

const [StylesProvider] = createStylesContext("NotFoundPage");

export const NotFoundPage: ReactFC<NotFoundPageProps> = ({ ...rest }) => {
	const t = useTranslatedString();
	const ctx = useRewriteContext();
	const CurrentBrandLogo = useBrandLogo(ctx.slug);
	const styles = useMultiStyleConfig("NotFoundPage", {});

	return (
		<StylesProvider value={styles}>
			<Container data-cy="404-content" sx={styles.container} {...rest}>
				<chakra.div __css={styles.backToContainer}>
					<Hx sx={styles.backToHeading}>{t("404")}</Hx>
					<Link href="/" sx={styles.backToLink}>
						<p>{t("backTo")}</p>
						<CurrentBrandLogo sx={styles.backToLogo} />
					</Link>
				</chakra.div>

				{/* TODO: uncomment when more brands are available */}
				{/* {allOtherBrands.length > 0 && (
					<HeadingLevelBoundary>
						<Hx size="h3" sx={styles.otherBrandsHeading}>
							{t("otherBrands")}
						</Hx>
						<chakra.ul __css={styles.otherBrandsList}>
							{allOtherBrands.map((brand) => {
								return (
									<OtherBrand
										key={brand.slug}
										brandSlug={brand.slug as BrandSlug}
										description={
											brand.seo?.description ?? undefined
										}
									/>
								);
							})}
						</chakra.ul>
					</HeadingLevelBoundary>
				)} */}
			</Container>
		</StylesProvider>
	);
};
